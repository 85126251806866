import {customElement} from 'lit/decorators.js';
import {sbkMainNavItemStyle} from './main-nav-item.styles';
import {html, LitElement} from 'lit';
import './main-nav-item.scss';

@customElement('sbk-main-nav-item')
export class SbkMainNavItem extends LitElement {

    static get styles() {
        return sbkMainNavItemStyle;
    }

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('role', 'listitem');
    }

    render() {
        return html`
            <div>
                <slot></slot>
            </div>
        `;
    }
}
