import { css } from 'lit';

export const style = css`

  * {
    box-sizing: border-box;
  }

  :host {
    display: flex;
    position: relative;
    box-sizing: border-box;
  }

  :host(:last-of-type) {
    font: var(--sbkBreadCrumbsTypographyBoldXs);
  }

  :host(:not(:last-child))::after {
    position: absolute;
    content: '';
    border-radius: var(--sbkBreadCrumbsBorderRadiusDivider);
    right: calc((var(--sbkBreadCrumbsSpacingGap) + var(--sbkBreadCrumbsSizingDividerWidth)) * -1);
    background: var(--sbkBreadCrumbsFgColorDefault);
    height: var(--sbkBreadCrumbsSizingDividerHeight);
    width: var(--sbkBreadCrumbsSizingDividerWidth);
  }

  div {
    display: flex;
  }
`;
