import {css, unsafeCSS} from 'lit';
import {sbkCoreBreakpointMd} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);

export const autocompleteIcdStyles = css`
    .autocomplete__wrapper {
        @media all and (max-width: ${breakpointMd}) {
            margin-top: var(--sbkCoreGridSmMargin);
        }
    }
`;
