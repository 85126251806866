import {customElement, state, property} from 'lit/decorators.js';
import {sbkNavFlyoutStyle} from './nav-flyout.styles';
import {html, LitElement, nothing} from 'lit';
import {SbkNavFlyoutList} from "@/components/nav-flyout-list/nav-flyout-list";
import {SbkLink} from "@/components/link/link";
import {TabItem} from "@/components/tab-menu-item/tab-menu-item";

@customElement('sbk-nav-flyout')
export class SbNavFlyout extends LitElement {

    @state()
    expanded = false;

    @state()
    hide = false;

    @property({type: Boolean})
    onScroll = false;

    @state()
    expandedSecondLevel = false;

    @state()
    expandedThirdLevel = false;

    @property({type: String, attribute: 'backlink-title'})
    backlinkTitle = ''

    @property({type: String})
    variant = ''

    static get styles() {
        return sbkNavFlyoutStyle;
    }

    constructor() {
        super()
        this.addEventListener('triggerDeeperLevel', this._triggerDeeperLevel);
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('keydown', this._handleKeyDown);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    private _handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && this.expanded) {
            this._handleTriggerClick(event)
        }
    };

    render() {
        return html`
            <slot name="trigger" @click=${this._handleTriggerClick}></slot>
            <div class="nav-flyout nav-flyout--${this.variant} ${this.expanded ? 'expanded' : ''} ${this.onScroll ? 'on-scroll' : ''}">
                <div class="nav-flyout__content-wrapper">
                    <div class="nav-flyout__controls ${this.expandedSecondLevel ? 'expandedSecondLevel' : ''}">
                        ${this.expandedSecondLevel || this.expandedThirdLevel ? html`
                            <sbk-link size="xs" icon="chevron-left" is-bold variant="regular"
                                      class="nav-flyout__controls-back"
                                      @click=${this._goBack}>
                                ${this.backlinkTitle}
                            </sbk-link>` : nothing}
                        <sbk-round-button class="nav-flyout__controls-close" icon="close" icon-only
                                          @click=${this._handleTriggerClick}>
                        </sbk-round-button>
                    </div>
                    <div class="nav-flyout__icons-wrapper ${this.hide ? 'hide' : ''}">
                        <slot name="icons"></slot>
                    </div>
                    <div class="nav-flyout__list-wrapper ${this.hide ? 'hide' : ''}">
                        <slot></slot>
                    </div>
                    <div
                        class="nav-flyout__list-wrapper secondLevel ${this.expandedSecondLevel ? 'expandedSecondLevel' : ''}">
                        <slot name="secondLevel"></slot>
                    </div>
                    <div
                        class="nav-flyout__list-wrapper thirdLevel ${this.expandedThirdLevel ? 'expandedThirdLevel' : ''}">
                        <slot name="thirdLevel"></slot>
                    </div>
                    <div class="nav-flyout__cta-wrapper">
                        <slot name="cta"></slot>
                    </div>
                    <div class="nav-flyout__meta-nav-wrapper ${this.hide ? 'hide' : ''}">
                        <slot name="meta-nav"></slot>
                    </div>
                </div>
            </div>
            <div class="nav-flyout-backdrop ${this.expanded ? 'expanded' : ''}"
                 @click=${this._handleTriggerClick}></div>
        `;
    }

    _handleTriggerClick(event: Event) {
        event.preventDefault()
        this.expanded = !this.expanded
        if (this.expanded) {
            document.body.classList.add('menu-open')
        } else {
            document.body.classList.remove('menu-open')
        }
        this._changeTriggerElement()
        this.hide = false
        const shadowRoot = this.shadowRoot
        if (shadowRoot) {
            this._closeDeeperLevel(shadowRoot, 'secondLevel')
            this._closeDeeperLevel(shadowRoot, 'thirdLevel')
        }
        this.expandedSecondLevel = false
        this.expandedThirdLevel = false
        const triggeredFlyout = new CustomEvent('triggeredFlyout', {
            bubbles: true,
            composed: true,
            detail: {
                id: this.id,
                expanded: this.expanded
            }
        })
        this.dispatchEvent(triggeredFlyout)
    }

    _goBack(event: Event) {
        event.preventDefault()
        const isSecondLevel = this.expandedSecondLevel
        const isThirdLevel = this.expandedThirdLevel
        if (isSecondLevel) {
            this.hide = false
            this.expandedSecondLevel = false
            this.expandedThirdLevel = false
        }

        if (isThirdLevel) {
            this.hide = true
            this.expandedSecondLevel = true
            this.expandedThirdLevel = false
        }

        const shadowRoot = this.shadowRoot
        if (shadowRoot) {
            const slotName = isSecondLevel ? 'secondLevel' : 'thirdLevel'
            this._closeDeeperLevel(shadowRoot, slotName)
        }
    }

    _triggerDeeperLevel(event: Event) {
        event.preventDefault()
        const isSecondLevel = (<CustomEvent>event).detail?.isSecondLevel
        this.hide = true
        if (isSecondLevel) {
            this.expandedThirdLevel = true
            this.expandedSecondLevel = false
        } else {
            this.expandedThirdLevel = false
            this.expandedSecondLevel = true
        }
        const shadowRoot = this.shadowRoot
        if (shadowRoot) {
            const slotName = this.expandedSecondLevel ? 'secondLevel' : 'thirdLevel'
            const slots: HTMLSlotElement[] = Array.from(shadowRoot.querySelectorAll(`slot[name="${slotName}"]`));
            slots.forEach(slot => {
                const elements = slot.assignedElements({flatten: true}) as SbkNavFlyoutList[];
                elements.forEach(element => {
                    if (element.id === String((<CustomEvent>event).detail?.id)) {
                        element.expanded = true
                    }
                })
            })
        }
    }

    _closeDeeperLevel(shadowRoot: ShadowRoot, slotName: string) {
        const slots: HTMLSlotElement[] = Array.from(shadowRoot.querySelectorAll(`slot[name="${slotName}"]`));
        slots.forEach(slot => {
            const elements = slot.assignedElements({flatten: true}) as SbkNavFlyoutList[];
            elements.forEach(element => {
                element.expanded = false
            })
        })
    }

    _changeTriggerElement() {
        const tabItem = this.querySelector('sbk-tab-menu-item[slot="trigger"]') as TabItem
        if (tabItem) {
            tabItem.setAttribute('aria-expanded', String(this.expanded))
        }
        const link = this.querySelector('sbk-link[slot="trigger"]') as SbkLink
        if (link) {
            link.setAttribute('aria-expanded', String(this.expanded))
            if (this.expanded && link.variant === 'icon-navigation') {
                link.icon = 'close-round'
                link.isSelected = true
            } else if (link.variant === 'icon-navigation') {
                link.icon = link.initialIcon
                link.isSelected = false
            }
        }
    }
}
