import {customElement, property, query, queryAssignedElements} from 'lit/decorators.js';
import {sbkMainNavStyle} from './main-nav.styles';
import {html, LitElement} from 'lit';
import {TabItem} from "@/components/tab-menu-item/tab-menu-item";
import {SbNavFlyout} from "@/components/nav-flyout/nav-flyout";
import {SbkSearchFlyout} from "@/components/search-flyout/search-flyout";
import {SbkContactFlyout} from "@/components/contact-flyout/contact-flyout.ts";
import {sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";
import {SbkMainNavItems} from "@/components/main-nav-items/main-nav-items.ts";
import {classMap,ClassInfo} from "lit/directives/class-map.js";
import {SbkModalFlyout} from "@/components/modal-flyout/modal-flyout.ts";
import './main-nav.scss';

@customElement('sbk-main-nav')
export class SbkMainNav extends LitElement {

    @property({type: String, attribute: 'logo-path'})
    logoPath = ''

    @property({type: String, attribute: 'logo-path-mobile'})
    logoPathMobile = ''

    @property({type: String, attribute: 'logo-alt-text'})
    logoAltText = ''

    @property({type: String, attribute: 'logo-link'})
    logoLink = ''

    @property({type: Boolean})
    active = false

    @property({type: Boolean})
    isPinned = false

    @property({type: Boolean, attribute: 'not-sticky'})
    notSticky = false

    @property({type: Boolean, attribute: 'show-logo-only-mobile'})
    showLogoOnlyMobile  = false

    @property({type: String})
    variant = ''

    @query('#sensor')
    sensorElement!: Element;

    @queryAssignedElements()
    _mainNavItems!: Array<LitElement>;

    static get styles() {
        return sbkMainNavStyle;
    }

    constructor() {
        super();
        this.addEventListener('triggeredFlyout', this._triggeredFlyout)
        this.addEventListener('triggeredSearchFlyout', this._triggeredSearchFlyout)
        this.addEventListener('triggeredContactFlyout', this._triggeredContactFlyout)
        this.addEventListener('triggeredModalFlyout', this._triggeredModalFlyout)
    }

    render() {
        return html`
            <div id="sensor"></div>
            <nav class=${classMap(this.getClassMap())} role="navigation"
                 aria-label="tbd">
                <div class="main-nav__wrapper">
                    <div class="logo__wrapper">
                        ${this.renderLogoLink()}
                    </div>
                    <div class="main-nav-items__wrapper">
                        <div class="main-nav-items">
                            <slot @slotchange=${this._onMainNavItemsSlotChange}></slot>
                        </div>
                        <div class="icon-nav-items">
                            <span class="verticalDivider" hidden></span>
                            <slot name="meta-nav-items"></slot>
                        </div>
                    </div>
                    <slot name="cta-nav-items" class="cta-nav-items__wrapper"></slot>
                </div>
            </nav>
        `;
    }

    renderLogoLink() {
        if (!this.logoLink) {
            return this.renderLogo();
        }
        return html`
            <sbk-link href=${this.logoLink}>
                ${this.renderLogo()}
            </sbk-link>
        `;
    }

    renderLogo() {
        if (!this.logoPath) {
            return '';
        }
        const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (this.logoPathMobile && viewWidth < parseInt(sbkCoreBreakpointMd.replace("px",""))) {
            return html`
                <img src=${this.logoPathMobile} alt=${this.logoAltText} class="logo logo--mobile"/>
            `;
        }
        return html`
            <img src=${this.logoPath} alt=${this.logoAltText} class="logo"/>
        `;
    }

    firstUpdated() {
        const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        if (!this.notSticky && viewWidth >= parseInt(sbkCoreBreakpointXl.replace("px",""))) {
            this._handleScroll()
        }
    }

    _handleScroll() {
        const observer = new IntersectionObserver(
            ([e]) => {
                if (e.intersectionRatio < 1) {
                    this.isPinned = true
                } else {
                    this.isPinned = false
                }
                const mainNavItemLists: SbkMainNavItems[] = Array.from(this.querySelectorAll('sbk-main-nav-items'))
                mainNavItemLists.forEach((mainNavItemList) => {
                    if (e.intersectionRatio < 1) {
                        mainNavItemList.onScroll = true
                    } else {
                        mainNavItemList.onScroll = false
                    }
                })
                const tabItems: TabItem[] = Array.from(this.querySelectorAll('sbk-tab-menu-item'))
                tabItems.forEach((tabItem) => {
                    if (e.intersectionRatio < 1) {
                        tabItem.size = "small"
                    } else {
                        tabItem.size = "large"
                    }
                })
                const flyouts: SbNavFlyout[] = Array.from(this.querySelectorAll('sbk-nav-flyout'))
                flyouts.forEach((flyout) => {
                    if (e.intersectionRatio < 1) {
                        flyout.onScroll = true
                    } else {
                        flyout.onScroll = false
                    }
                })
            },
            {threshold: [1]}
        );
        observer.observe(this.sensorElement);
    }

    _onMainNavItemsSlotChange = () => {
        const verticalDivider: HTMLElement | null = this.renderRoot.querySelector('.verticalDivider');
        if (verticalDivider && this._mainNavItems.length) {
            verticalDivider.hidden = false;
        }
    }

    _triggeredFlyout(event: Event) {
        const id = (<CustomEvent>event).detail?.id
        const expanded = (<CustomEvent>event).detail?.expanded
        this.active = expanded
        if (expanded) {
            const flyouts: SbNavFlyout[] = Array.from(this.querySelectorAll('sbk-nav-flyout'))
            flyouts.forEach((flyout) => {
                if (flyout.id !== id) {
                    flyout.expanded = false
                    flyout._changeTriggerElement()
                }
            })
            this._hideSearchFlyout();
            this._hideContactFlyout();
            this._hideModalFlyout();
        }
    }

    _triggeredSearchFlyout(event: Event) {
        const expanded = (<CustomEvent>event).detail?.expanded
        this.active = expanded
        if (expanded) {
            const flyouts: SbNavFlyout[] = Array.from(this.querySelectorAll('sbk-nav-flyout'))
            flyouts.forEach((flyout) => {
                flyout.expanded = false
                flyout._changeTriggerElement();
            })
            this._hideContactFlyout();
            this._hideModalFlyout();
        }
    }

    _triggeredContactFlyout(event: Event) {
        const expanded = (<CustomEvent>event).detail?.expanded
        this.active = expanded
        if (expanded) {
            const flyouts: SbNavFlyout[] = Array.from(this.querySelectorAll('sbk-nav-flyout'))
            flyouts.forEach((flyout) => {
                flyout.expanded = false
                flyout._changeTriggerElement();
            })
            this._hideSearchFlyout();
            this._hideModalFlyout();
        }
    }

    _triggeredModalFlyout(event: Event) {
        const expanded = (<CustomEvent>event).detail?.expanded
        this.active = expanded
        if (expanded) {
            const flyouts: SbNavFlyout[] = Array.from(this.querySelectorAll('sbk-nav-flyout'))
            flyouts.forEach((flyout) => {
                flyout.expanded = false
                flyout._changeTriggerElement();
            })
            this._hideSearchFlyout();
            this._hideContactFlyout();
        }
    }

    private _hideSearchFlyout() {
        const searchFlyouts: SbkSearchFlyout[] = Array.from(this.querySelectorAll('sbk-search-flyout'))
        searchFlyouts.forEach((searchFlyout) => {
            searchFlyout.expanded = false
            searchFlyout._changeTriggerElement()
        })
    }

    private _hideContactFlyout() {
        const contactFlyouts: SbkContactFlyout[] = Array.from(this.querySelectorAll('sbk-contact-flyout'))
        contactFlyouts.forEach((contactFlyout) => {
            contactFlyout.expanded = false
            contactFlyout._changeTriggerElement()
        })
    }

    private _hideModalFlyout() {
        const modalFlyouts: SbkModalFlyout[] = Array.from(this.querySelectorAll('sbk-modal-flyout'))
        modalFlyouts.forEach((modalFlyout) => {
            modalFlyout.expanded = false
            modalFlyout._changeTriggerElement()
        })
    }


    getClassMap(): ClassInfo {
        return {
            'main-nav': true,
            [`main-nav--${this.variant}`]: !!this.variant,
            'active': this.active,
            'is-pinned': this.isPinned,
            'not-sticky': this.notSticky,
            'main-nav--no-logo-desktop': this.showLogoOnlyMobile,
        };
    }
}
