import { LitElement, html } from 'lit';
import {customElement, property, state, queryAll, queryAssignedElements} from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { searchInputStyle } from './search-input.styles';
import {query} from "lit/decorators/query.js";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

type SuggestItem = {
  label: string
  value: string
};

@customElement('sbk-search')
export class SearchComponent extends LitElement {

    @property()
    action = '';

    @property()
    method = '';

    @property({attribute: 'reset-button-label'})
    resetButtonLabel = '';

    @property({attribute: 'submit-button-label'})
    submitButtonLabel = '';

    @property({attribute: 'input-name'})
    inputName = '';

    @property({attribute: 'input-placeholder'})
    inputPlaceholder = '';

    @property({attribute: 'input-value'})
    inputValue = '';

    @property({attribute: 'suggest-uri'})
    suggestUri = '';

    @property({attribute: 'teaser-uri'})
    teaserUri = '';

    @property({attribute: 'has-dropdown'})
    hasDropdown = false;

    @property({attribute: 'in-flyout'})
    inFlyout = false;

    @property({type: String})
    query = '';

    @queryAssignedElements({slot: 'additionalInputs'})
    _assignedElements!: Array<HTMLElement>;

    @query('form')
    _form!: HTMLFormElement;

    @state()
    _expanded = false;

    @state()
    suggestions: SuggestItem[] = [];

    @query('input')
    _combobox!: HTMLInputElement

    @query('[role="listbox"]')
    _listbox!: HTMLElement

    @queryAll('[role="option"]')
    _optionNodes!: HTMLElement[]

    listboxHasVisualFocus = false;
    comboboxHasVisualFocus = false;

    option: HTMLElement | null = null;

    firstOption: HTMLElement | null = null;

    lastOption: HTMLElement | null = null;

    isList = true;
    isNone = false;
    isBoth = false;
    hasHover = false;

    constructor() {
        super();
        // @ts-expect-error wrong return type
        this.onComboboxKeyUp = this.debounce(this.onComboboxKeyUp.bind(this), 1000);
    }

    connectedCallback() {
        super.connectedCallback();
    document.addEventListener(
        'pointerup',
        this.onBackgroundPointerUp,
        true
    );
  }

  disconnectedCallback() {
    document.removeEventListener(
        'pointerup',
        this.onBackgroundPointerUp,
        true
    );
    super.disconnectedCallback();
  }

  static get styles() {
    return searchInputStyle;
  }

  render() {
    return html`
      <form action=${this.action} method=${this.method}>
        <slot @slotchange=${this._addAdditionalInputs} name="additionalInputs"></slot>
        <div class="search-container">
          <div class="input-wrapper">
            <input
                id="searchInput"
                class="input"
                type="search"
                name=${this.inputName}
                placeholder=${this.inputPlaceholder}
                .value=${this.inputValue}
                @keydown=${this.onComboboxKeyDown}
                @keyup=${this.onComboboxKeyUp}
                @focus=${this.onComboboxFocus}
                @blur=${this.onComboboxBlur}
                @click=${this.onComboboxClick}
                role="combobox"
                aria-expanded=${this._expanded}
                autocomplete="off"
                aria-autocomplete="list"
                aria-controls="listbox" />
            ${this.renderSuggestions()}
            <sbk-round-button size="extra-large" type="submit" icon="search" variant="icon-only" class="search-button">${this.submitButtonLabel}</sbk-round-button>
          </div>
        </div>
      </form>
    `;
  }

  renderSuggestions() {
    return html`
        <ul role="listbox"
            @pointerover=${this.onListboxPointerover}
            @pointerout=${this.onListboxPointerout}
            id="listbox"
            aria-label="Autocomplete Search"
            class="autocomplete-items ${this.hasDropdown ? 'autocomplete--has-dropdown' : 'autocomplete--without-dropdown'} ${this.inFlyout ? 'autocomplete--flyout' : ''}">
            ${map(this.suggestions,
                (suggestion: SuggestItem, index: number) => html`
                    <li id="option${index + 1}"
                        role="option"
                        class="autocomplete-item ${this.inFlyout ? 'autocomplete-item--flyout' : ''}"
                        @click=${this.onOptionClick}
                        @pointerover=${this.onOptionPointerover}
                        @pointerout=${this.onOptionPointerout}
                        data-suggestion=${suggestion.value}>
                        <sbk-icon symbol="search" size=${this.inFlyout ? '24' : '20'}></sbk-icon>
                        <span>${unsafeHTML(this._decorateAutosuggestion(suggestion.label))}</span></li>
                `
        )}
      </ul>
    `;
  }

  _decorateAutosuggestion(label: string) {
    const pattern = new RegExp(this._combobox.value, 'gi');
    return label.replace(
          pattern,
          '<strong>$&</strong>'
    );
  }

  _addAdditionalInputs() {
    this._form.append(...this._assignedElements);
  }

  debounce<T extends (...args:Parameters<T>) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
    let timeout: ReturnType<typeof setTimeout>;
    return function(this: ThisParameterType<T>, ...args: Parameters<T>): void {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  async _querySuggestions(input: string) {
      if (input.length < 3) {
          this.suggestions = [];
          this.close(true)
          return;
      }
      await this._getSolrSuggestions(input);
      //await this._getSearchTeaser(input);
      await this.updateComplete;
  }

  async _getSolrSuggestions(term: string) {
    return fetch(this.suggestUri + '&' + new URLSearchParams({
      "tx_solr[queryString]": term.toLowerCase(),
      "tx_solr[callback]": ''
    }))
        .then((response) => {
          return response.text()
        })
        .then((data) => {
          const solrSuggestions = JSON.parse(data).suggestions
          const allSuggestions: SuggestItem[] = [];
          Object.entries(solrSuggestions).forEach((solrSuggestion) => {
            allSuggestions.push( {
              label: solrSuggestion[0],
              value: solrSuggestion[0]
            } );
          });
          this.suggestions = allSuggestions;
        })
  }

  async _getSearchTeaser(term: string) {
    return fetch(this.teaserUri + '?' + new URLSearchParams({
      "q": term,
    }), {
      method: 'POST',
    })
        .then((response) => {
          return response.text()
        })
        .then((data) => {
          let searchTeaserSuggestions = JSON.parse( data ).suggestions;

          if(searchTeaserSuggestions) {
            searchTeaserSuggestions = Object.keys(searchTeaserSuggestions);
          } else {
            searchTeaserSuggestions = [];
          }
          return searchTeaserSuggestions;
        })
  }

  getLowercaseContent(node: HTMLElement): string {
    return node.textContent?.toLowerCase() ?? '';
  }

  isOptionInView(option: HTMLElement) {
    const bounding = option.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  setActiveDescendant(option: HTMLElement|false) {
    if (option && this.listboxHasVisualFocus) {
      this._combobox.setAttribute('aria-activedescendant', option.id);
      if (!this.isOptionInView(option)) {
        option.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    } else {
      this._combobox.setAttribute('aria-activedescendant', '');
    }
  }

  setValue(value: string) {
    this._combobox.value = value;
    this._combobox.setSelectionRange(value.length, value.length);
    //this.filterOptions();
  }

  setOption(option: HTMLElement|null, flag: boolean = false) {
    if (option) {
      this.option = option;
      this.setCurrentOptionStyle(this.option);
      this.setActiveDescendant(this.option);

      if (this.isBoth) {
        this._combobox.value = this.option.dataset.suggestion ?? '';
        if (flag) {
          if (this.option.textContent) {
            this._combobox.setSelectionRange(
                this.option.textContent.length,
                this.option.textContent.length
            );
          }
        } else {
          if (this.option.textContent) {
            this._combobox.setSelectionRange(
                this._combobox.value.length,
                this.option.textContent.length
            );
          }
        }
      }
    }
  }

  setVisualFocusCombobox() {
    this._listbox.classList.remove('focus');
    this._combobox.classList.add('focus'); // set the focus class to the parent for easier styling
    this.comboboxHasVisualFocus = true;
    this.listboxHasVisualFocus = false;
    this.setActiveDescendant(false);
  }

  setVisualFocusListbox() {
    this._combobox.classList.remove('focus');
    this.comboboxHasVisualFocus = false;
    this.listboxHasVisualFocus = true;
    this._listbox.classList.add('focus');
    this.option && this.setActiveDescendant(this.option);
  }

  removeVisualFocusAll() {
    this._combobox.classList.remove('focus');
    this.comboboxHasVisualFocus = false;
    this.listboxHasVisualFocus = false;
    this._listbox.classList.remove('focus');
    this.option = null;
    this.setActiveDescendant(false);
  }

  // ComboboxAutocomplete Events

  async filterOptions() {
    await this._querySuggestions(this._combobox.value);
    let option = null;
    const currentOption = this.option;

    // Use populated options array to initialize firstOption and lastOption.
    const numItems = this._optionNodes.length;

    if (numItems > 0) {
      this.firstOption = this._optionNodes[0];
      this.lastOption = this._optionNodes[numItems - 1];

      if (currentOption && this._optionNodes.indexOf(currentOption) >= 0) {
        option = currentOption;
      } else {
        option = this.firstOption;
      }
    } else {
      this.firstOption = null;
      option = null;
      this.lastOption = null;
    }

    return option;
  }

  setCurrentOptionStyle(option: HTMLElement|boolean) {
    for (let i = 0; i < this._optionNodes.length; i++) {
      const opt = this._optionNodes[i];
      if (opt === option) {
        opt.setAttribute('aria-selected', 'true');
        if (
            this._listbox.scrollTop + this._listbox.offsetHeight <
            opt.offsetTop + opt.offsetHeight
        ) {
          this._listbox.scrollTop =
              opt.offsetTop + opt.offsetHeight - this._listbox.offsetHeight;
        } else if (this._listbox.scrollTop > opt.offsetTop + 2) {
          this._listbox.scrollTop = opt.offsetTop;
        }
      } else {
        opt.removeAttribute('aria-selected');
      }
    }
  }

  getPreviousOption(currentOption: HTMLElement|null) {
    if (currentOption && currentOption !== this.firstOption) {
      const arr = Array.from(this._optionNodes);
      const index = arr.indexOf(currentOption);
      return arr[index - 1];
    }
    return this.lastOption;
  }

  getNextOption(currentOption: HTMLElement|null) {
    if (currentOption && currentOption !== this.lastOption) {
      const arr = Array.from(this._optionNodes);
      const index = arr.indexOf(currentOption);
      return arr[index + 1];
    }
    return this.firstOption;
  }

  /* MENU DISPLAY METHODS */

  doesOptionHaveFocus() {
    return this._combobox.getAttribute('aria-activedescendant') !== '';
  }

  isOpen() {
    return this._listbox.style.display === 'block';
  }

  isClosed() {
    return this._listbox.style.display !== 'block';
  }

  hasOptions() {
    return this._optionNodes.length;
  }

  open() {
      this._listbox.style.display = 'block';
      this._expanded = true;
      this.triggerHideSearchSuggestion(true);
  }

  close(force: boolean = false) {
    if (
        force ||
        (!this.comboboxHasVisualFocus &&
            !this.listboxHasVisualFocus &&
            !this.hasHover)
    ) {
        this.setCurrentOptionStyle(false);
        this._listbox.style.display = 'none';
        this._expanded = false;
        this.setActiveDescendant(false);
        this._combobox.classList.add('focus');
        this.triggerHideSearchSuggestion(false);
    }
  }

    triggerHideSearchSuggestion(state: boolean) {
        const triggerEvent = new CustomEvent('triggerHideSearchSuggestion', {
            bubbles: true,
            composed: true,
            detail: {
                hideSearchSuggestion: state
            }
        })
        this.dispatchEvent(triggerEvent)
    }

    /* combobox Events */

    async onComboboxKeyDown(event: KeyboardEvent) {
        let flag = false;
        const altKey = event.altKey;

        if (event.ctrlKey || event.shiftKey) {
            return;
        }

        switch (event.key) {
            case 'Enter':
                if (this.listboxHasVisualFocus) {
                    this.setValue(this.option?.dataset.suggestion ?? '');
                }
                this.close(true);
                this.setVisualFocusCombobox();
                flag = true;
                this._form.submit();
                break;

            case 'Down':
            case 'ArrowDown':
                if (this._optionNodes.length > 0) {
                    if (altKey) {
                        this.open();
                    } else {
                        this.open();
                        if (
                            this.listboxHasVisualFocus ||
                            (this.isBoth && this._optionNodes.length > 1)
                        ) {
                            this.setOption(this.getNextOption(this.option), true);
                            this.setVisualFocusListbox();
                        } else {
                            this.setOption(this.firstOption, true);
                            this.setVisualFocusListbox();
                        }
                    }
                }
                flag = true;
                break;

            case 'Up':
            case 'ArrowUp':
                if (this.hasOptions()) {
                    if (this.listboxHasVisualFocus) {
                        this.setOption(this.getPreviousOption(this.option), true);
                    } else {
                        this.open();
                        if (!altKey) {
                            this.setOption(this.lastOption, true);
                            this.setVisualFocusListbox();
                        }
                    }
                }
                flag = true;
                break;

            case 'Esc':
            case 'Escape':
                if (this.isOpen()) {
                    this.close(true);
                    await this.filterOptions();
                    this.setVisualFocusCombobox();
                } else {
                    this.setValue('');
                    this._combobox.value = '';
                }
                this.option = null;
                flag = true;
                break;

            case 'Tab':
                this.close(true);
                if (this.listboxHasVisualFocus) {
                    if (this.option) {
                        this.setValue(this.option?.dataset.suggestion ?? '');
                    }
                }
                break;

            case 'Home':
                this._combobox.setSelectionRange(0, 0);
                flag = true;
                break;

            case 'End':
                this._combobox.setSelectionRange(this._combobox.value.length, this._combobox.value.length);
                flag = true;
                break;

            default:
                break;
        }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  isPrintableCharacter(str: string) {
    return str.length === 1 && str.match(/\S| /);
  }

  async onComboboxKeyUp(event: KeyboardEvent) {
    let flag = false,
        option = null;
    const char = event.key;

    if (event.key === 'Escape' || event.key === 'Esc') {
      return;
    }

    switch (event.key) {
      case 'Backspace':
        this.setVisualFocusCombobox();
        this.setCurrentOptionStyle(false);
        this.option = null;
        await this.filterOptions();
        flag = true;
        break;

      case 'Left':
      case 'ArrowLeft':
      case 'Right':
      case 'ArrowRight':
      case 'Home':
      case 'End':
        this.option = null;
        this.setCurrentOptionStyle(false);
        this.setVisualFocusCombobox();
        flag = true;
        break;

      default:
        if (this.isPrintableCharacter(char)) {
          this.setVisualFocusCombobox();
          this.setCurrentOptionStyle(false);
          flag = true;


          if (this.isList || this.isBoth) {
            option = await this.filterOptions();
            if (option) {
              if (this.isClosed() && this._combobox.value.length) {
                this.open();
              }

              if (
                  this.getLowercaseContent(option).indexOf(
                      this._combobox.value.toLowerCase()
                  ) === 0
              ) {
                this.option = option;
                if (this.isBoth || this.listboxHasVisualFocus) {
                  this.setCurrentOptionStyle(option);
                  if (this.isBoth) {
                    this.setOption(option);
                  }
                }
              } else {
                this.option = null;
                this.setCurrentOptionStyle(false);
              }
            } else {
              this.close();
              this.option = null;
              this.setActiveDescendant(false);
            }
          } else if (this._combobox.value.length) {
            this.open();
          }
        }

        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onComboboxClick() {
    if (this._optionNodes.length === 0) {
      return;
    }
    if (this.isOpen()) {
      this.close(true);
    } else {
      this.open();
    }
  }

  onComboboxFocus() {
    this.setVisualFocusCombobox();
    this.option = null;
    this.setCurrentOptionStyle(false);
  }

  onComboboxBlur() {
    this.removeVisualFocusAll();
  }

  onBackgroundPointerUp = (event: Event) => {
    if (event.target && event.target instanceof Node &&
        this !== event.target &&
        !this._combobox.contains(event.target) &&
        !this._listbox.contains(event.target)
    ) {
      this.comboboxHasVisualFocus = false;
      this.setCurrentOptionStyle(false);
      this.removeVisualFocusAll();
      setTimeout(this.close.bind(this, true), 300);
    }
  }

  /* Listbox Events */

  onListboxPointerover() {
    this.hasHover = true;
  }

  onListboxPointerout() {
    this.hasHover = false;
    setTimeout(this.close.bind(this, false), 300);
  }

  // Listbox Option Events

  onOptionClick(event: Event) {
    if (event.target && event.target instanceof HTMLElement) {
        let option: HTMLElement|null = event.target;
        if (!event.target.classList.contains('autocomplete-item')) {
            option = option.closest('.autocomplete-item');
        }
      this._combobox.value = option?.dataset.suggestion ?? '';
      this.close(true);
        this._form.submit();
    }
  }

  onOptionPointerover() {
    this.hasHover = true;
    this.open();
  }

  onOptionPointerout() {
    this.hasHover = false;
    setTimeout(this.close.bind(this, false), 300);
  }
}
