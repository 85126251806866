import { css, unsafeCSS } from 'lit';
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from "@/tokens/variables";

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const style = css`
  :host {
    display: block;
    height: 47.5px;
    min-height: 47.5px;
      margin: 0 auto 0 auto;
      max-width: calc(var(--sbkCoreContentAreaMaxWidth) + 2*var(--sbkCoreGridXlMargin));
      position: relative;
      padding: 0 var(--sbkCoreGridSmMargin);
  }

    @media (min-width: ${breakpointMd}) {
        :host {
            height: 52px;
            min-height: 52px;
            padding: 0 var(--sbkCoreGridMdMargin);
        }
    }
    @media (min-width: ${breakpointLg}) {
        :host {
            padding: 0 var(--sbkCoreGridLgMargin);
        }
    }
    @media (min-width: ${breakpointXl}) {
        :host {
            padding: 0 var(--sbkCoreGridXlMargin);
        }
    }

    

  .container {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  ol {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    gap: calc(var(--sbkBreadCrumbsSpacingGap) * 2 + var(--sbkBreadCrumbsSizingDividerWidth));
  }

  .fade {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20%;
    pointer-events: none;
    z-index: 1;
  }

  .fade-left {
    left: 0;
    background: linear-gradient(to right, var(--sbkColorContentBgColorDefault), rgba(255, 255, 255, 0));
  }

  .fade-right {
    right: 0;
    background: linear-gradient(to left, var(--sbkColorContentBgColorDefault), rgba(255, 255, 255, 0));
  }

`;
